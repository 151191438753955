@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;700&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

html {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  overflow: hidden;
}

@font-face {
  font-family: "KyoboHand";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@1.0/KyoboHand.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  /* font-family: "Noto Sans KR", sans-serif; */
  font-family: "KyoboHand", sans-serif;
}

#app {
  width: 100vw;
  height: 100vh;
}

header {
  position: fixed;
  display: flex;
  width: 100vw;
  background-color: transparent;
  top: 0;
  z-index: 1;
  text-align: center;
  justify-content: space-between;
}

.link {
  background-color: white;
  color: black;
  border: 1px solid black;
  padding: 4px 8px;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 10px;
}

.image-gallery {
  max-width: 100vw !important;
}
